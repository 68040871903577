/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.firmware-item {
  position: relative;
  width: 100%;
  padding: 8px 16px;
  font-size: 0.95em;
}
.firmware-item .actions {
  position: absolute;
  top: 8px;
  right: 18px;
}
.firmware-item .title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  color: #D22027;
  margin-bottom: 4px;
}
.firmware-item .remark {
  font-size: 0.9em;
  color: #8c8c8c;
  margin-bottom: 8px;
}
