/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.firmware-set-card {
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 24px;
  text-align: left;
  position: relative;
}
.firmware-set-card .empty {
  color: #f5222d;
  padding: 8px;
  background-color: transparent;
}
.firmware-set-actions {
  position: absolute;
  right: 24px;
  top: 16px;
}
.firmware-set-header {
  margin-bottom: 12px;
}
.firmware-set-header .title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  color: #14837B;
}
.firmware-set-header .remark {
  color: #8c8c8c;
  font-size: 0.95em;
  margin-top: 4px;
  padding-bottom: 8px;
}
.section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  color: #14837B;
  text-decoration: underline;
  margin-bottom: 8px;
}
.mobile .section-title,
.tablet .section-title {
  font-size: 1em;
}
.models-row {
  margin-bottom: 32px;
}
.firmwares-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: stretch;
}
.firmwares-row .firmwares-column {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
}
.firmwares-row .firmwares-column .firmwares-actions {
  position: absolute;
  top: 4px;
  right: 4px;
}
.firmwares-row .firmware-set-usage-stat {
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #d9d9d9;
}
.mobile .firmwares-row {
  flex-direction: column;
}
.mobile .firmwares-row .firmware-set-usage-stat {
  border-left: none;
  padding-left: 0;
  margin-left: 0;
  margin-top: 32px;
}
